import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import './styles.scss';

export default class Switch extends PureComponent {
    handleClick = (e) => {
        const { onChange, id, value, disabled } = this.props;
        e.preventDefault();
        if (!disabled) {
            onChange(id, !value);
        }
    };

    handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Enter') {
            this.handleClick(e);
        }
    };

    render() {
        const { value, disabled, lg, dark, errors, gray } = this.props;

        const switchClasses = ClassNames('Switch', {
            'Switch--selected': value,
            'Switch--notselected': !value,
            'Switch--disabled': disabled,
            'Switch--small': !lg,
            'Switch--error': errors && errors.length > 0,
            'Switch--dark': dark,
            'Switch--gray': gray,
        });

        return (
            <button
                type="button"
                className={switchClasses}
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                role="switch"
                aria-checked={value}
                aria-disabled={disabled}
                aria-label={value ? 'Switch is on' : 'Switch is off'}
                disabled={disabled}
                tabIndex={disabled ? undefined : 0}
            >
                <div className="Switch__button" />
            </button>
        );
    }
}

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.bool,
    lg: PropTypes.bool,
    disabled: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
    onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
    value: false,
    lg: false,
    disabled: false,
    dark: false,
    gray: false,
    errors: [],
};