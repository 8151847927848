/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';

import ReactEcharts from 'echarts-for-react';
import {
    defaultTooltip,
    getDefaultTooltipFormatter,
    textStyle,
    defaultDataZoom,
    getXAxisOptions,
    getYAxisOptions,
    defaultLegend,
    defaultStyle,
} from '../../helpers/echarts';

const WWComposed = (props) => {
    const {
        data,
        series,
        layout,
        xDataKey,
        yDataKey,
        zDataKey,
        gradientDataKey,
        xValueFormatter,
        yValueFormatter,
        xAxisZoomVisible,
        yAxisZoomVisible,
        xAxisType,
        yAxisType,
        hasLegend,
        legendItemGap,
        legendPosition,
        hasTooltip,
        isAnimationActive,
        barGap,
        barCategoryGap,
        colorFunction,
        gradientColors,
        serumNameFormatter,
        tooltipFormatter,
        dependentSeriesProps,
        topGridPadding,
        bottomGridPadding,
        showVisualMap,
        xAxisSlantLabels,
    } = props;

    // exclusively used for bins to ensure x axis renders empty ranges & tooltip shows on hover
    if (series?.independent[0]?.operation === 'bin' && series.independent[0].max > data.length) {
        [...Array(series.independent[0].max).keys()].forEach((e) => {
            if (data[e][xDataKey] !== e + 1) {
                data.splice(e, 0, { [series.dependent[0].name]: 0, [xDataKey]: e + 1 });
            }
        });
    }

    // if (series?.independent[0]?.operation === 'bin' && series.independent[0].max > data.length) {
    //     [...Array(series.independent[0].max).keys()].forEach((e) => {
    //         if (data[e] && data[e][xDataKey] !== e + 1) {
    //             data.splice(e, 0, { [series.dependent[0].name]: 0, [xDataKey]: e + 1 });
    //         } else if (!data[e]) {
    //             // If data[e] does not exist, create a placeholder entry
    //             data.push({ [series.dependent[0].name]: 0, [xDataKey]: e + 1 });
    //         }
    //     });
    // }

    const gridConfig = {
        top: hasLegend && legendPosition === 'top' ? '25%' : '10%', // Add padding when legend is on top
        bottom: hasLegend && legendPosition === 'bottom' ? '15%' : '5%', // Increase bottom padding if legend is at the bottom
        left: hasLegend && legendPosition === 'left' ? `35%` : '5%', // Add padding when legend is on left
        right: hasLegend && legendPosition === 'right' ? `35%` : '5%', // Add padding when legend is on right
        containLabel: true,
    };
    
    const legendConfig = {
        ...defaultLegend,
        show: hasLegend,
        type: 'scroll',
        itemGap: legendItemGap, // Reduced gap between legend items
        orient: legendPosition === 'top' || legendPosition === 'bottom' ? 'horizontal' : 'vertical',
        ...(legendPosition === 'top' && { top: '5%', left: 'center' }),
        ...(legendPosition === 'bottom' && { top: 'bottom', left: 'center' }),
        ...(legendPosition === 'left' && { left: '5%', top: 'middle' }), // Center vertically
        ...(legendPosition === 'right' && { right: '5%', top: 'middle' }), // Center vertically
        data: series.dependent.map((serum) => serumNameFormatter(serum)),
    };

    const options = {
        tooltip: {
            ...defaultTooltip,
            show: hasTooltip,
            trigger:
                dependentSeriesProps[Object.keys(dependentSeriesProps)[0]].chartType === 'scatter'
                    ? 'item'
                    : 'axis',
            formatter:
                dependentSeriesProps[Object.keys(dependentSeriesProps)[0]].chartType === 'scatter'
                    ? getDefaultTooltipFormatter(yValueFormatter, xValueFormatter, tooltipFormatter)
                    : getDefaultTooltipFormatter(xValueFormatter, yValueFormatter, tooltipFormatter),
        },
        legend: legendConfig,
        xAxis: {
            ...getXAxisOptions(props),
            boundaryGap: ['20%', '0%'],
            silent: false,
        },
        yAxis: {
            ...getYAxisOptions(props),
        },
        visualMap: series.gradientDimension.length
            ? {
                  // what series & min/max
                  dimension: 3,
                  min: series?.gradientDimension[0]?.min,
                  max: series?.gradientDimension[0]?.max,
                  // whether you have sliders or just click and drag on it
                  calculable: true,
                  // colours for heat map
                  color: gradientColors(),

                  // visuals for legend
                  show: showVisualMap,
                  itemHeight: '200',
                  itemWidth: '15',
                  align: 'left',
                  top: hasLegend ? '35' : '0',
                  right: '15',
                  orient: 'horizontal', // 'horizontal' or 'vertical'
                  type: 'continuous', // 'piecewise' or 'continuous',
                  textStyle: {
                      color: '#A1ABC8',
                  },
                  zlevel: 5,
              }
            : null,
        series: series.dependent.map((serum) => ({
            type:
                dependentSeriesProps[serum.serumId].chartType === 'area'
                    ? 'line'
                    : dependentSeriesProps[serum.serumId].chartType,
            areaStyle:
                dependentSeriesProps[serum.serumId].chartType === 'line'
                    ? { opacity: 0 }
                    : {
                          ...(dependentSeriesProps[serum.serumId].fillOpacity && {
                              opacity: dependentSeriesProps[serum.serumId].fillOpacity,
                          }),
                      },
            itemStyle: ['bar', 'scatter'].includes(dependentSeriesProps[serum.serumId].chartType)
                ? {
                      ...(dependentSeriesProps[serum.serumId].fillOpacity && {
                          opacity: dependentSeriesProps[serum.serumId].fillOpacity,
                      }),
                  }
                : {},
            name: serumNameFormatter(serum),
            color: colorFunction(serum),
            smooth: dependentSeriesProps[serum.serumId].lineType === 'smooth',
            stack: dependentSeriesProps[serum.serumId].stackId,
            step: dependentSeriesProps[serum.serumId].lineType === 'step' ? 'middle' : '',
            symbol:
                dependentSeriesProps[serum.serumId].chartType === 'scatter' ||
                dependentSeriesProps[serum.serumId].hasDot
                    ? 'circle'
                    : 'none',
            animation: isAnimationActive,
            connectNulls: dependentSeriesProps[serum.serumId].connectNulls,
            symbolSize:
                series.otherDimensions[0]?.min !== series.otherDimensions[0]?.max
                    ? function (val) {
                          return (
                              (0.5 +
                                  (val[2] - series.otherDimensions[0].min) /
                                      ((series.otherDimensions[0].max - series.otherDimensions[0].min) / 2)) *
                              dependentSeriesProps[serum.serumId].dotSize
                          );
                      }
                    : dependentSeriesProps[serum.serumId].dotSize,
            ...{ barGap, barCategoryGap },
            progressive: 2000,
            progressiveThreshold: 5000,
            data: data.map((dataPoint) => ({
                // the format we use
                ...dataPoint,
                // the format echarts axis uses
                // these extra conditionals remove the empty column from appearing at the start of the x axis when using bins
                value:
                    layout === 'horizontal'
                        ? [
                              dataPoint[xDataKey],
                              dataPoint[serum.name],
                              dataPoint[zDataKey],
                              dataPoint[gradientDataKey],
                          ]
                        : [
                              dataPoint[serum.name],
                              dataPoint[yDataKey],
                              dataPoint[zDataKey],
                              dataPoint[gradientDataKey],
                          ]
            })),
            // .filter((point) => point.value[0] !== undefined && point.value[1] !== undefined), // makes sure stacks are always working
        })),

        // grid: {
        //     left: yAxisZoomVisible || xAxisSlantLabels ? 40 : 20,
        //     right: 20,
        //     bottom: bottomGridPadding,
        //     top: topGridPadding,
        //     containLabel: true,
        // },

        grid: gridConfig,
        
        // use start and end to start zoomed in
        dataZoom: [
            {
                ...defaultDataZoom,
                height: 3,
                bottom: 20,
                show: xAxisZoomVisible,
                labelFormatter:
                    xAxisType === 'category' ? (i, value) => xValueFormatter(value) : xValueFormatter,
            },
            {
                ...defaultDataZoom,
                width: 3,
                left: 15,
                show: yAxisZoomVisible,
                orient: 'vertical',
                labelFormatter:
                    yAxisType === 'category' ? (i, value) => yValueFormatter(value) : yValueFormatter,
            },
        ],
        textStyle,
    }

    console.log("options", options)

    return (
        <ReactEcharts
            notMerge
            style={defaultStyle}
            option={options}
        />
    );
};

export default WWComposed;
