/* eslint react/forbid-prop-types: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClassNames from 'classnames';

import ChartUnit from '..';
import { getSerumFormatter, makeGetSerumColor } from '../../serum';
import { getMainChartTypeMetadata, updateChartObject, getChartProps, getChartSettings } from '../helpers';

import Header from './Header';
import MappingBucket from '../../components/MappingBucket';
import UnitLoader from '../../components/Loader';
import Settings from '../../components/Settings';
import FeatureBasketWithOperation from '../../components/FeatureBasket/operation';

import DependentBasket from './baskets/dependent';
import GroupByBasket from './baskets/groupBy';
import OrderByBasket from './baskets/orderBy';
import PopOver from '../../../Access/_components/PopOver';
import { ScrollArea } from '../../../shared';

require('./styles.scss');

class ChartMapping extends Component {
    constructor(props) {
        super(props);
        this.state = { settingsOpen: false };
    }
    handleChange = (id, value) => {
        const { onChange, unit, features } = this.props;
        const result = updateChartObject(id, value, unit, features);
        if (result) {
            const { updateObj, shouldGetData } = result;
            onChange(updateObj, { shouldGetData });
        }
    };
    render = () => {
        const { settingsOpen } = this.state;
        const { unit, features, datasets, dashboardColors, datasetIds, handleFilterChange, selectedDatasetId } =
            this.props;
        const { vis } = unit;
        const mappingConfig = getMainChartTypeMetadata(vis).mapping;
        let xAxes = vis.display?.dependentAxis === 'x' ? vis.dependent : vis.independent;
        let yAxes = vis.display?.dependentAxis === 'x' ? vis.independent : vis.dependent;
        if (!xAxes || !xAxes.length) xAxes = [{ series: [], display: {} }];
        if (!yAxes || !yAxes.length) yAxes = [{ series: [], display: {} }];
        const getColorForSeries = makeGetSerumColor(vis.dependent[0]?.series || [], {
            ...dashboardColors,
            ...vis.colors,
        });

        const showSourceNames = [xAxes[0].series, yAxes[0].series, vis.groupBy, vis.orderBy].flat().some((serum) => {
            const serumFeature = features.byId[serum?.feature?._id];
            return selectedDatasetId && serumFeature?.dataset._id !== selectedDatasetId;
        });

        const dependentRenderer = (dndProps) => (
            <DependentBasket
                dndProps={dndProps}
                features={features}
                datasets={datasets}
                showSourceNames={showSourceNames}
                seriesColors={vis.colors?.series}
                showColors={!vis.groupBy.length}
                getColorForSeries={getColorForSeries}
                operationId={vis.display?.dependentAxis === 'x' ? 'xOperation' : 'yOperation'}
                mainChartType={vis.chartType || 'area'}
                onChange={this.handleChange}
            />
        );

        const independentRenderer = (dndProps) => (
            <FeatureBasketWithOperation
                dndProps={dndProps}
                features={features}
                datasets={datasets}
                showSourceNames={showSourceNames}
                operationId={vis.display?.dependentAxis === 'x' ? 'yOperation' : 'xOperation'}
                onChange={this.handleChange}
            />
        );

        return (
            <div className={ClassNames('ChartMapping', { 'ChartMapping--settingsOpen': settingsOpen })}>
                {!settingsOpen && (
                    <div className="ChartMapping__AxisContainer">
                        {!mappingConfig.yAxis.disabled && (
                            <MappingBucket
                                // values
                                id="yNugget"
                                onChange={this.handleChange}
                                series={yAxes[0].series}
                                // dnd behaviour
                                allowMultiple={
                                    mappingConfig.allowMultiple !== false && vis.display?.dependentAxis !== 'x'
                                }
                                additionalSerumValues={{ display: {} }}
                                // display
                                label={mappingConfig.yAxis.title}
                                placeholder={mappingConfig.yAxis.placeholder}
                                render={vis.display?.dependentAxis === 'x' ? independentRenderer : dependentRenderer}
                                className="ChartMapping__yAxis"
                            />
                        )}
                        {!yAxes[0].series.length > 0 && !xAxes[0].series.length > 0 && selectedDatasetId && (
                            <PopOver
                                body="Drag & drop columns to visualize the data."
                                arrow="bottom"
                                buttonText="Cool!"
                                link="https://www.whitewhale.ai/getting-started-tutorials/5-build-your-first-dashboard#editing-a-visualization"
                                type="mappingPopovers"
                                style={{ bottom: '149px', left: '350px' }}
                            />
                        )}
                        {yAxes[0].series.length > 0 && xAxes[0].series.length > 0 && (
                            <PopOver
                                body="Here is where you can perform data operations."
                                arrow="top"
                                buttonText="Sweet!"
                                type="mappingPopovers"
                                link="https://www.whitewhale.ai/getting-started-tutorials/5-build-your-first-dashboard#editing-a-visualization"
                                style={{ top: '116px', left: '10px', width: '175px' }}
                                neverShow
                            />
                        )}
                    </div>
                )}
                <div className="ChartMapping__Chart">
                    <Header
                        unit={unit}
                        onChange={this.handleChange}
                        toggleSettings={() => this.setState({ settingsOpen: !settingsOpen })}
                        disableFullSettings={!(unit.data && unit.data.data && unit.data.data.length)}
                        settingsOpen={settingsOpen}
                        datasetIds={Array.from(new Set([selectedDatasetId, ...datasetIds]))} // send selectedDatasetId to allow for filtering in draft state
                        handleFilterChange={handleFilterChange}
                    >
                        {!unit.hydrated && <UnitLoader />}
                        <ChartUnit 
                            key={JSON.stringify(unit.vis.display)} // Forces re-render on config change
                            unit={unit} 
                            colors={dashboardColors} 
                            features={features} 
                            mapping 
                            legendPosition={unit.vis.display?.legendPosition}
                            legendItemGap={unit.vis.display?.legendItemGap}
                        />
                    </Header>
                    {!mappingConfig.xAxis.disabled && !settingsOpen && (
                        <MappingBucket
                            // values
                            id="xNugget"
                            onChange={this.handleChange}
                            series={xAxes[0].series}
                            // dnd behaviour
                            horizontal
                            allowMultiple={mappingConfig.allowMultiple !== false && vis.display?.dependentAxis === 'x'}
                            additionalSerumValues={{ display: {} }}
                            // display
                            label={mappingConfig.xAxis.title}
                            placeholder={mappingConfig.xAxis.placeholder}
                            render={vis.display?.dependentAxis === 'x' ? dependentRenderer : independentRenderer}
                        />
                    )}
                </div>
                {!settingsOpen && (
                    <ScrollArea className="ChartMapping__AxisContainer__ScrollArea">
                        <div className="ChartMapping__AxisContainer ChartMapping__AxisContainer--right">
                            {!mappingConfig.groupBy.disabled && (
                                <MappingBucket
                                    // values
                                    id="groupByNugget"
                                    onChange={this.handleChange}
                                    series={vis.groupBy}
                                    // dnd behaviour
                                    allowMultiple={false}
                                    additionalSerumValues={(featureId) => {
                                        // could add multiple groups here
                                        const featureType = features.byId[featureId]?.type;
                                        if (featureType === 'date') return { operation: 'year' };
                                        if (featureType === 'number') return { operation: 'bin' };
                                        return {};
                                    }}
                                    // display
                                    expandable
                                    label="Group"
                                    placeholder="Drag to group data"
                                    render={(dndProps) => (
                                        <GroupByBasket
                                            dndProps={dndProps}
                                            features={features}
                                            datasets={datasets}
                                            showSourceNames={showSourceNames}
                                            groupColors={vis.colors?.groups}
                                            operationId="groupOperation"
                                            formatter={getSerumFormatter(vis.groupBy[0], features)}
                                            getColorForSeries={getColorForSeries}
                                            data={unit.data}
                                            onChange={this.handleChange}
                                        />
                                    )}
                                />
                            )}
                            {!mappingConfig.orderBy.disabled && (
                                <MappingBucket
                                    // values
                                    id="orderByNugget"
                                    onChange={this.handleChange}
                                    series={vis.orderBy}
                                    // dnd behaviour
                                    allowMultiple={false}
                                    additionalSerumValues={{ type: 'ASC' }}
                                    // display
                                    expandable
                                    label="Order"
                                    placeholder="Drag to order data"
                                    render={(dndProps) => (
                                        <OrderByBasket
                                            dndProps={dndProps}
                                            features={features}
                                            datasets={datasets}
                                            showSourceNames={showSourceNames}
                                            operationId="orderByOperation"
                                            onChange={this.handleChange}
                                            unit={unit}
                                        />
                                    )}
                                />
                            )}
                            {mappingConfig.zAxis && mappingConfig.zAxis.display && (
                                <MappingBucket
                                    // values
                                    id="zNugget"
                                    onChange={this.handleChange}
                                    series={vis.otherDimensions[0]?.series || []}
                                    // dnd behaviour
                                    allowMultiple={false}
                                    // display
                                    expandable
                                    label="Dot Size"
                                    placeholder="Drag numeric data"
                                    render={(dndProps) => (
                                        <FeatureBasketWithOperation
                                            dndProps={dndProps}
                                            features={features}
                                            datasets={datasets}
                                            showSourceNames={showSourceNames}
                                            operationId="zOperation"
                                            onChange={this.handleChange}
                                        />
                                    )}
                                />
                            )}
                            {mappingConfig.gradientAxis && mappingConfig.gradientAxis.display && (
                                <MappingBucket
                                    // values
                                    id="gradientNugget"
                                    onChange={this.handleChange}
                                    series={vis.gradientDimension[0]?.series || []}
                                    // dnd behaviour
                                    allowMultiple={false}
                                    // display
                                    expandable
                                    label="Gradient"
                                    placeholder="Drag numeric data"
                                    render={(dndProps) => (
                                        <FeatureBasketWithOperation
                                            dndProps={dndProps}
                                            features={features}
                                            datasets={datasets}
                                            showSourceNames={showSourceNames}
                                            operationId="gradientOperation"
                                            onChange={this.handleChange}
                                        />
                                    )}
                                />
                            )}
                            {!mappingConfig.tooltip.disabled && (
                                <MappingBucket
                                    // values
                                    id="tooltipNugget"
                                    onChange={this.handleChange}
                                    series={vis?.tooltip?.series || []}
                                    // dnd behaviour
                                    allowMultiple
                                    // display
                                    expandable
                                    label="Tooltip"
                                    placeholder="Drag tooltip data"
                                    render={(dndProps) => (
                                        <FeatureBasketWithOperation
                                            dndProps={dndProps}
                                            features={features}
                                            datasets={datasets}
                                            showSourceNames={showSourceNames}
                                            operationId="tooltipOperation"
                                            onChange={this.handleChange}
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </ScrollArea>
                )}
                {settingsOpen && (
                    <div className="ChartMapping__settings">
                        <Settings
                            onChange={this.handleChange}
                            toggleExpanded={() => this.setState({ settingsOpen: false })}
                            settings={getChartSettings(vis, getChartProps(vis, unit.data, dashboardColors))}
                            buttons={[
                                {
                                    key: '1',
                                    onClick: () => this.handleChange('resetDefaultSettings'),
                                    content: 'Reset defaults',
                                },
                                {
                                    key: '2',
                                    onClick: () => this.handleChange('clearUnitColors'),
                                    content: 'Reset colors to default',
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        );
    };
}

ChartMapping.propTypes = {
    unit: PropTypes.shape({
        vis: PropTypes.shape({
            dependent: PropTypes.arrayOf(PropTypes.shape({ series: PropTypes.array })),
            independent: PropTypes.arrayOf(PropTypes.shape({ series: PropTypes.array })),
            display: PropTypes.shape({ dependentAxis: PropTypes.string }),
            chartType: PropTypes.string.isRequired,
            colors: PropTypes.shape({
                series: PropTypes.shape({}),
                groups: PropTypes.shape({}),
                gradients: PropTypes.shape({}),
            }),
            groupBy: PropTypes.arrayOf(PropTypes.shape({})),
            filters: PropTypes.arrayOf(PropTypes.shape({})),
            kpi: PropTypes.shape({}),
            orderBy: PropTypes.arrayOf(PropTypes.shape({})),
            otherDimensions: PropTypes.arrayOf(PropTypes.shape({ series: PropTypes.array })),
            gradientDimension: PropTypes.arrayOf(PropTypes.shape({ series: PropTypes.array })),
            tooltip: PropTypes.shape({ series: PropTypes.array }),
        }),
        type: PropTypes.string,
        data: PropTypes.shape({
            data: PropTypes.array,
            series: PropTypes.shape({
                dependent: PropTypes.array,
            }),
        }),
        hydrated: PropTypes.bool,
    }),
    dashboardColors: PropTypes.shape({}),
    features: PropTypes.shape({ byId: PropTypes.shape({}) }).isRequired,
    datasets: PropTypes.shape({ byId: PropTypes.shape({}) }).isRequired,
    onChange: PropTypes.func.isRequired,
    datasetIds: PropTypes.arrayOf(PropTypes.string),
    handleFilterChange: PropTypes.func.isRequired,
    selectedDatasetId: PropTypes.string,
};
ChartMapping.defaultProps = {
    unit: {
        vis: {},
        hydrated: false,
    },
    dashboardColors: null,
    datasetIds: [],
    selectedDatasetId: '',
};

const mapStateToProps = (state, props) => {
    const dashboard = state.dashboards.byId[props.dashboardId];
    return {
        features: state.features,
        datasets: state.datasets,
        dashboardColors: dashboard?.colors,
    };
};

const connectedComponent = connect(mapStateToProps)(ChartMapping);
export default connectedComponent;
