import React from 'react';
import { neutral20 } from '../../../../../styles/partials/colors';
import {
    getSerumFormatter,
    getSerumDisplayName,
    getDefaultAxisTitle,
    getSerumType,
    makeGetSerumColor,
} from '../../../serum';
import AngledTick from '../../components/AngledTick';

export const _getDefaultScale = (type) => (type === 'category' ? 'auto' : 'linear');

export const _getDefaultPieRadiusForMultiAxis = (i, numberOfSeries) => ({
    inner: (i * 60) / numberOfSeries,
    outer: ((i + 1) * 60) / numberOfSeries,
});

const _getTooltipFormatter = (tooltipSeries, features) => {
    if (!tooltipSeries || !tooltipSeries.length) return null; // tooltip renderer getDefaultTooltipFormatter will not create any new lines for tooltip series in this case
    // function that returns a list of formatted tooltip values
    return (data) =>
        tooltipSeries.map(
            (serum) =>
                `${getSerumDisplayName(serum, null, features)}: ${getSerumFormatter(serum, features)(data[serum.id])}`,
        );
};

export const getLegendProps = (display = {}) => ({
    hasLegend: display.hasLegend !== undefined ? display.hasLegend : true,
    legendPosition: display.legendPosition || 'top',
    // legendBackgroundColor: display.legendBackgroundColor || '#ffffff',
    // legendTextColor: display.legendTextColor || '#000000',
});

// -----------------------------------------------------
//                    Y Props
// -----------------------------------------------------

export const _getYProps = (vis, series, colors, features) => {
    const valueFormatter = getSerumFormatter(series.dependent[0], features); // should always be type number
    const serumDisplayNames = {};
    series.dependent.forEach((serum) => {
        serumDisplayNames[serum.name] = getSerumDisplayName(serum, vis.groupBy, features);
    });
    const labelFormatter = (seriesName) => serumDisplayNames[seriesName];
    const tooltipFormatter = _getTooltipFormatter(vis.tooltip && vis.tooltip.series, features);
    const margin = { top: 5, bottom: 5, left: 5, right: 5 };
    const hasLegend =
        vis.display.hasLegend === undefined
            ? Boolean(series.dependent.length > 1 || vis.groupBy.length)
            : vis.display.hasLegend;
    const legendPosition = vis.display.legendPosition || 'top';
    const legendItemGap = vis.display.legendItemGap || '10';
    const hasTooltip = vis.display.hasTooltip === undefined ? true : vis.display.hasTooltip;
    const showVisualMap = vis.display.showVisualMap === undefined ? true : vis.display.showVisualMap;
    const hasAnimation = vis.display.hasAnimation === undefined ? true : vis.display.hasAnimation;
    const colorScheme = colors.colorScheme;
    const colorFunction = makeGetSerumColor(vis.dependent[0]?.series, colors);

    return {
        margin,
        hasLegend,
        legendPosition,
        legendItemGap,
        hasTooltip,
        showVisualMap,
        hasAnimation,
        valueFormatter,
        labelFormatter,
        tooltipFormatter,
        colorScheme,
        colorFunction,
    };
};

// -----------------------------------------------------
//                  XY Props
// -----------------------------------------------------

export const _getXYProps = (vis, series, colors, features) => {
    let xAxis = vis.display.dependentAxis === 'x' ? vis.dependent[0] : vis.independent[0];
    let yAxis = vis.display.dependentAxis === 'x' ? vis.independent[0] : vis.dependent[0];
    const zAxis = vis.otherDimensions[0] || { display: {}, series: [] }; // dot size
    const gradientAxis = vis.gradientDimension[0] || { display: {}, series: [] }; // heat map
    const _xSeries = xAxis && xAxis.series[0] ? xAxis.series[0] : { feature: {} };
    const _ySeries = yAxis && yAxis.series[0] ? yAxis.series[0] : { feature: {} };
    const _zSeries = zAxis && zAxis.series[0] ? zAxis.series[0] : { feature: {} };
    const _gradientSeries = gradientAxis && gradientAxis.series[0] ? gradientAxis.series[0] : { feature: {} };
    const dependentSerum =
        vis.dependent[0] && vis.dependent[0].series[0] ? vis.dependent[0].series[0] : { feature: {} };
    if (!xAxis) xAxis = { display: {}, series: [] };
    if (!yAxis) yAxis = { display: {}, series: [] };
    const _yType = getSerumType(_ySeries, features) || 'category'; // defaulting to category so boxplot with no independent series works
    const _xType = getSerumType(_xSeries, features) || 'category'; // defaulting to category so boxplot with no independent series works
    const gradientColors = () => {
        if (vis.chartType === 'scatter' && vis.gradientDimension.length && vis?.colors?.gradients) {
            if (vis.colors.gradients.High && !vis.colors.gradients.Low) {
                return [vis.colors.gradients.High, '#f6efa6'];
            }
            if (vis.colors.gradients.Low && !vis.colors.gradients.High) {
                return ['#bf444c', vis.colors.gradients.Low];
            }
            return [vis.colors.gradients.High, vis.colors.gradients.Low];
        }
        return ['#bf444c', '#f6efa6'];
    };
    const xValueFormatter = getSerumFormatter(_xSeries, features);
    const yValueFormatter = getSerumFormatter(_ySeries, features);
    const zValueFormatter = getSerumFormatter(_zSeries, features); // currently only used for scatter dotSize
    const gradientValueFormatter = getSerumFormatter(_gradientSeries, features); // currently only used for scatter gradient
    let tooltipSeries = []; // tooltips, also z axis
    const hasScatterChart = vis.dependent[0].series.some((serum) => serum.chartType === 'scatter');
    if (hasScatterChart && vis.otherDimensions[0] && vis.otherDimensions[0].series.length) {
        tooltipSeries = tooltipSeries.concat(vis.otherDimensions[0].series);
    }
    if (hasScatterChart && vis.gradientDimension[0] && vis.gradientDimension[0].series.length) {
        tooltipSeries = tooltipSeries.concat(vis.gradientDimension[0].series);
    }
    if (vis.tooltip && vis.tooltip.series.length) {
        tooltipSeries = tooltipSeries.concat(vis.tooltip.series);
    }
    const tooltipFormatter = _getTooltipFormatter(tooltipSeries, features);

    const serumNameFormatter = (serum) => getSerumDisplayName(serum, vis.groupBy, features);

    const margin = { top: 5, bottom: 5, left: 5, right: 5 };
    const layout = vis.display.dependentAxis === 'x' ? 'vertical' : 'horizontal';

    const dependentAxis = vis.display.dependentAxis;
    const xAxisTitle = xAxis.title || getDefaultAxisTitle(xAxis, features) || '';
    const yAxisTitle = yAxis.title || getDefaultAxisTitle(yAxis, features) || '';
    const zAxisTitle = zAxis.title || getDefaultAxisTitle(zAxis, features) || '';
    const gradientAxisTitle = gradientAxis.title || getDefaultAxisTitle(gradientAxis, features) || '';
    const xAxisZoomVisible = xAxis.display.zoomVisible === undefined ? false : xAxis.display.zoomVisible;
    const yAxisZoomVisible = yAxis.display.zoomVisible === undefined ? false : yAxis.display.zoomVisible;
    const xAxisHasTitle = xAxis.display.hasTitle === undefined ? true : xAxis.display.hasTitle;
    const yAxisHasTitle = yAxis.display.hasTitle === undefined ? true : yAxis.display.hasTitle;
    const xAxisHasLabels = xAxis.display.hasLabels === undefined ? true : xAxis.display.hasLabels;
    const xAxisSlantLabels = xAxis.display.slant === undefined ? false : xAxis.display.slant;
    const yAxisHasLabels = yAxis.display.hasLabels === undefined ? true : yAxis.display.hasLabels;
    const xAxisScale = !xAxis.display.scale ? _getDefaultScale(_xType) : xAxis.display.scale;
    const yAxisScale = !yAxis.display.scale ? _getDefaultScale(_yType) : yAxis.display.scale;

    // const roundUp = (min) => {
    //     // for numeric data with positive min value, or numeric data with null min
    //     if (min <= 0) {
    //         return min;
    //     }
    //     return min;
    //     // for numeric data with negative min value
    //     // if (min < 0) {
    //     //     const hasBigNumber = Math.ceil(Math.log10(-min + 1)) > 4 ? 2 : 1;
    //     //     const n = 10 ** (Math.ceil(Math.log10(-min + 1)) - hasBigNumber);
    //     //     return -Math.ceil(-min / n) * n;
    //     // }
    //     // // for non numeric data
    //     // return null;
    // };

    // range default settings 'auto'
    let yAxisMax = () => null;
    let yAxisMin = () => null;

    // range min. set to 'data'
    if (yAxis.display.typeMin === 'data' || _yType === 'date') {
        yAxisMin = 'dataMin';
    } else if (layout === 'horizontal' && _yType !== 'number') {
        yAxisMin = 'dataMin';
    }
    // range max. set to 'data'
    if (yAxis.display.typeMax === 'data' || _yType === 'date') {
        yAxisMax = 'dataMax';
    } else if (layout === 'horizontal' && _yType !== 'number') {
        yAxisMax = 'dataMax';
    }
    // range min. set to 'fixed'
    const yAxisFixedMin = yAxis.display.valueMin;
    if (yAxis.display.valueMin !== null && yAxis.display.typeMin === 'fixed' && _yType === 'number') {
        yAxisMin = () => yAxisFixedMin;
    }
    // range max. set to 'fixed'
    const yAxisFixedMax = yAxis.display.valueMax;
    if (yAxis.display.valueMax !== null && yAxis.display.typeMax === 'fixed' && _yType === 'number') {
        yAxisMax = () => yAxisFixedMax;
    }

    // range default settings 'auto'
    let xAxisMax = () => null;
    // let xAxisMin = () => null;

    let xAxisMin = () => {
        let minValue = null;
        series[layout === 'horizontal' ? 'independent' : 'dependent'].forEach((e) => {
            minValue = e.feature.type === 'number' || e.operation === 'count' ? e.min : null;
        });
        return minValue;
    };

    // domain min. set to 'data'
    if (xAxis.display.typeMin === 'data' || _xType === 'date') {
        xAxisMin = 'dataMin';
    } else if (layout === 'vertical' && _xType !== 'number') {
        xAxisMin = 'dataMin';
    }
    // domain max. set to 'data'
    if (xAxis.display.typeMax === 'data' || _xType === 'date') {
        xAxisMax = 'dataMax';
    } else if (layout === 'vertical' && _xType !== 'number') {
        xAxisMax = 'dataMax';
    }
    // domain min. set to 'fixed'
    const xAxisFixedMin = xAxis.display.valueMin;
    if (xAxis.display.valueMin !== null && xAxis.display.typeMin === 'fixed' && _xType === 'number') {
        xAxisMin = () => xAxisFixedMin;
    }
    // domain max. set to 'fixed'
    const xAxisFixedMax = xAxis.display.valueMax;
    if (xAxis.display.valueMax !== null && xAxis.display.typeMax === 'fixed' && _xType === 'number') {
        xAxisMax = () => xAxisFixedMax;
    }

    const xAxisOrientation = xAxis.display.orientation === undefined ? 'bottom' : xAxis.display.orientation;
    const yAxisOrientation = yAxis.display.orientation === undefined ? 'left' : yAxis.display.orientation;

    const xDataKey = layout === 'horizontal' && series.independent[0] ? series.independent[0].name : undefined;
    const yDataKey = layout === 'vertical' && series.independent[0] ? series.independent[0].name : undefined;
    const zDataKey = series.otherDimensions && series.otherDimensions[0] && series.otherDimensions[0].name;
    const gradientDataKey = series.gradientDimension && series.gradientDimension[0] && series.gradientDimension[0].name;

    // set xAxis.type and yAxis.type in line with echarts options, fixes bug with date on independent axis
    let xAxisType;
    if (_xType === 'category') {
        xAxisType = 'category';
    } else if (_xType === 'date') {
        xAxisType = 'time';
    } else {
        xAxisType = 'value';
    }

    let yAxisType;
    if (_yType === 'category') {
        yAxisType = 'category';
    } else if (_yType === 'date') {
        yAxisType = 'time';
    } else {
        yAxisType = 'value';
    }

    if (dependentSerum.chartType === 'bar') {
        yAxisType = layout === 'vertical' ? 'category' : 'value';
    }
    if (dependentSerum.chartType === 'boxplot' || vis.display.stackGroups === 'all') {
        // for boxplot, independent axis is always shown as a category
        // stack only works with echarts category on independent axis for area/bar/line
        if (layout === 'vertical') {
            yAxisType = 'category';
        } else {
            xAxisType = 'category';
        }
    }

    let xAxisTickSettings = {};
    let yAxisTickSettings = {};
    let xAxisTickFunction = () => xAxisHasLabels && { fill: neutral20 };
    let yAxisTickFunction = () => yAxisHasLabels && { fill: neutral20 };
    const categoryTickFunction = (valueFormatter) => <AngledTick valueFormatter={valueFormatter} />;
    // set custom interval for category axes
    if (
        vis.independent.length &&
        vis.independent[0].series.length &&
        vis.independent[0].series[0].feature &&
        features.byId[vis.independent[0].series[0].feature._id] &&
        features.byId[vis.independent[0].series[0].feature._id].type === 'category'
    ) {
        if (layout === 'horizontal') {
            xAxisTickSettings = { interval: 0, height: 90 };
            xAxisTickFunction = categoryTickFunction;
        } else {
            yAxisTickSettings = { interval: 0, width: 90 };
            yAxisTickFunction = categoryTickFunction;
        }
    }
    if (
        vis.dependent.length &&
        vis.dependent[0].series.length &&
        vis.dependent[0].series[0].feature &&
        features.byId[vis.dependent[0].series[0].feature._id] &&
        features.byId[vis.dependent[0].series[0].feature._id].type === 'category'
    ) {
        if (layout === 'horizontal') {
            yAxisTickSettings = { interval: 0, width: 90 };
            yAxisTickFunction = categoryTickFunction;
        } else {
            xAxisTickSettings = { interval: 0, height: 90 };
            xAxisTickFunction = categoryTickFunction;
        }
    }

    const xTickCount = 10;
    const yTickCount = 5;
    const xTickSize = xAxisHasLabels ? 6 : 0;
    const yTickSize = yAxisHasLabels ? 6 : 0;

    // reversing a date/number axis is the only way to make it DESC order. only do this for the independent axis though, for consistency with how the rest of order works
    const xReversed =
        xAxis.display.reversed ||
        Boolean(
            vis.orderBy[0] &&
                vis.orderBy[0].feature._id === _xSeries.feature._id &&
                vis.display.dependentAxis !== 'x' &&
                _xType !== 'category' &&
                vis.orderBy[0].type === 'DESC',
        );
    const yReversed =
        yAxis.display.reversed ||
        Boolean(
            vis.orderBy[0] &&
                vis.orderBy[0].feature._id === _ySeries.feature._id &&
                vis.display.dependentAxis === 'x' &&
                _yType !== 'category' &&
                vis.orderBy[0].type === 'DESC',
        );

    const xAxisLabelPosition = xAxisOrientation === 'bottom' ? 'insideBottomRight' : 'insideTopRight';
    const xAxisLabelOffset = 0;
    let yAxisLabelPosition = yAxisOrientation === 'left' ? 'insideBottomLeft' : 'insideBottomRight';
    let yAxisLabelAngle = -90;
    if (dependentSerum.chartType === 'radar') {
        yAxisLabelPosition = 'outside';
        yAxisLabelAngle = 0;
    }

    const gridVertical =
        !vis.display.grid || vis.display.grid.vertical === undefined
            ? layout === 'vertical'
            : vis.display.grid.vertical;
    const gridHorizontal =
        !vis.display.grid || vis.display.grid.horizontal === undefined
            ? layout === 'horizontal'
            : vis.display.grid.horizontal;

    const hasLegend =
        vis.display.hasLegend === undefined
            ? Boolean(series.dependent.length > 1 || vis.groupBy.length)
            : vis.display.hasLegend;
    const legendPosition = vis.display.legendPosition || 'top';
    const legendItemGap = vis.display.legendItemGap || 10;
    const hasTooltip = vis.display.hasTooltip === undefined ? true : vis.display.hasTooltip;
    const showVisualMap = vis.display.showVisualMap === undefined ? true : vis.display.showVisualMap;
    const hasAnimation = vis.display.hasAnimation === undefined ? true : vis.display.hasAnimation;

    // used in scatter plot
    const allowDuplicatedCategory = false;

    // only used for radar charts
    const radarOuterRadius =
        !vis.dependent[0] || vis.dependent[0].display.radarOuterRadius === undefined
            ? 70
            : vis.dependent[0].display.radarOuterRadius;
    const radarStartAngle =
        !vis.dependent[0] || vis.dependent[0].display.radarStartAngle === undefined
            ? 90
            : vis.dependent[0].display.radarStartAngle;
    // bar charts
    const barGap = vis.display.barGap === undefined ? null : vis.display.barGap;
    const barCategoryGap = vis.display.barCategoryGap === undefined ? null : vis.display.barCategoryGap;
    const stackOffset = vis.display.stackOffset || 'none';
    const colorScheme = colors.colorScheme;
    const colorFunction = makeGetSerumColor(vis.dependent[0]?.series, colors);

    // Grid padding
    const getTopGridPadding = () => {
        const defaultPadding = 30;
        const legend = 30;
        const axisTitle = 17;
        const visualMap = 20;
        if (hasLegend && yAxisHasTitle && showVisualMap) return defaultPadding + legend + axisTitle + visualMap;
        if (hasLegend && yAxisHasTitle && !showVisualMap) return defaultPadding + legend + axisTitle;
        if (hasLegend && !yAxisHasTitle && showVisualMap) return defaultPadding + legend + visualMap;
        if (hasLegend && !yAxisHasTitle && !showVisualMap) return defaultPadding + legend;
        if (!hasLegend && yAxisHasTitle && showVisualMap) return defaultPadding + axisTitle + visualMap;
        if (!hasLegend && yAxisHasTitle && !showVisualMap) return defaultPadding + axisTitle;
        if (!hasLegend && !yAxisHasTitle && showVisualMap) return defaultPadding + visualMap;
        return defaultPadding;
    };

    const getBottomGridPadding = () => {
        const defaultPadding = 13;
        const zoom = 17;
        const axisTitle = 12;
        if (zoom && xAxisHasTitle) return defaultPadding + zoom + axisTitle - 2;
        if (zoom && !xAxisHasTitle) return defaultPadding + zoom - 5;
        if (!zoom && xAxisHasTitle) return defaultPadding + zoom + axisTitle + 5;
        return defaultPadding;
    };

    const topGridPadding = getTopGridPadding();
    const bottomGridPadding = getBottomGridPadding();

    return {
        _xType,
        _yType,
        xValueFormatter,
        yValueFormatter,
        zValueFormatter,
        gradientValueFormatter,
        tooltipFormatter,
        margin,
        layout,
        xDataKey, // the key for the x axis in the data returned, only used when it's the independent axis (otherwise each serum has its own key)
        yDataKey, // the key for the y axis in the data returned, only used when it's the independent axis
        zDataKey, // key for the z axis
        gradientDataKey, // key for the gradient
        dependentAxis,
        xAxisTitle,
        yAxisTitle,
        zAxisTitle,
        gradientAxisTitle,
        xAxisZoomVisible,
        yAxisZoomVisible,
        xAxisHasTitle,
        yAxisHasTitle,
        xAxisHasLabels,
        xAxisSlantLabels,
        yAxisHasLabels,
        xAxisOrientation,
        yAxisOrientation,
        xAxisScale,
        yAxisScale,
        xAxisType,
        yAxisType,
        xAxisTickSettings,
        yAxisTickSettings,
        xAxisTickFunction,
        yAxisTickFunction,
        xTickCount,
        yTickCount,
        xTickSize,
        yTickSize,
        xReversed,
        yReversed,
        xAxisLabelPosition,
        xAxisLabelOffset,
        yAxisLabelPosition,
        yAxisLabelAngle,
        gridVertical,
        gridHorizontal,
        hasLegend,
        legendPosition,
        legendItemGap,
        showVisualMap,
        hasTooltip,
        hasAnimation,
        radarOuterRadius,
        radarStartAngle,
        barGap,
        barCategoryGap,
        stackOffset,
        colorScheme,
        colorFunction,
        gradientColors,
        serumNameFormatter,
        allowDuplicatedCategory,
        xAxisMin,
        yAxisMin,
        xAxisMax,
        yAxisMax,
        yAxisFixedMin,
        yAxisFixedMax,
        xAxisFixedMin,
        xAxisFixedMax,
        topGridPadding,
        bottomGridPadding,
    };
};

// -----------------------------------------------------
//                Specific Axis Props
// -----------------------------------------------------
export const _getSpecificPieAxisProps = (axis, i, numberOfAxes) => ({
    innerRadius:
        axis.display.innerRadius === undefined
            ? _getDefaultPieRadiusForMultiAxis(i, numberOfAxes).inner
            : axis.display.innerRadius,
    outerRadius:
        axis.display.outerRadius === undefined
            ? _getDefaultPieRadiusForMultiAxis(i, numberOfAxes).outer
            : axis.display.outerRadius,
    startAngle: axis.display.startAngle === undefined ? 0 : axis.display.startAngle,
    pieHasLabels: axis.display.pieHasLabels === undefined ? true : axis.display.pieHasLabels,
});

// -----------------------------------------------------
//                Specific Series Props
// -----------------------------------------------------

export const _getSpecificSeriesProps = (vis, serum, mainChartType) => {
    const visSerumIndex = vis.dependent[0].series.findIndex((s) => s.id === serum.serumId);
    const visSerum = vis.dependent[0].series[visSerumIndex];
    const chartType = visSerum.chartType || mainChartType;
    if (visSerum) {
        switch (chartType) {
            case 'area': {
                let stackId;
                if (vis.display.stackGroups === 'all') {
                    stackId = 'all';
                }
                return {
                    chartType,
                    lineType: visSerum.display.lineType === undefined ? 'linear' : visSerum.display.lineType,
                    connectNulls: true,
                    stackId,
                    hasDot:
                        visSerum.display.hasDot === undefined
                            ? serum.count < 200 || (serum.count < 400 && Boolean(serum.hasDuplicateIndependentValues))
                            : visSerum.display.hasDot,
                    fillOpacity: visSerum.display.fillOpacity === undefined ? 0.6 : visSerum.display.fillOpacity,
                    dotSize: visSerum.display.dotSize === undefined ? 10 : visSerum.display.dotSize,
                };
            }
            case 'line':
                return {
                    chartType,
                    lineType: visSerum.display.lineType === undefined ? 'linear' : visSerum.display.lineType,
                    connectNulls: true,
                    hasDot:
                        visSerum.display.hasDot === undefined
                            ? serum.count < 200 || (serum.count < 400 && Boolean(serum.hasDuplicateIndependentValues))
                            : visSerum.display.hasDot,
                    dotSize: visSerum.display.dotSize === undefined ? 10 : visSerum.display.dotSize,
                };
            case 'bar': {
                let stackId;
                if (vis.display.stackGroups === 'all') {
                    stackId = 'all';
                }
                return {
                    chartType,
                    stackId,
                    fillOpacity: visSerum.display.fillOpacity === undefined ? 1 : visSerum.display.fillOpacity,
                };
            }
            case 'radar':
                return {
                    chartType,
                    fillOpacity: visSerum.display.fillOpacity === undefined ? 0.4 : visSerum.display.fillOpacity,
                };
            case 'boxplot':
                return {
                    chartType,
                };
            case 'scatter':
                return {
                    chartType,
                    dotSize: visSerum.display.dotSize === undefined ? 10 : visSerum.display.dotSize,
                    fillOpacity: visSerum.display.fillOpacity === undefined ? 0.9 : visSerum.display.fillOpacity,
                };
            default:
                throw new Error('_getDefaultSeriesProps unexpected chart type');
        }
    }
    return {};
};

export const _addAllSpecificProps = (props, vis, series, mainChartType) => {
    const newProps = props;
    if (mainChartType === 'pie') {
        newProps.dependentAxisProps = vis.dependent.map((axis, i) =>
            _getSpecificPieAxisProps(axis, i, vis.dependent.length),
        );
    } else {
        newProps.dependentSeriesProps = {};
        series.dependent.forEach((serum) => {
            newProps.dependentSeriesProps[serum.serumId] = _getSpecificSeriesProps(vis, serum, mainChartType);
        });
    }
    return newProps;
};
