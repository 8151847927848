import { cloneDeep } from 'lodash';
import { updateSeriesNugget, checkAndSwitchAxes } from './helpers';
import { defaultChartModel } from '../../schema';
import { xyChartTypes } from '../constants';

export function updateChartObject(changeId, value, unit, features) {
    const updateObj = { vis: cloneDeep(unit.vis) };
    let shouldGetData = true;
    let shouldClearData = false;
    // changeId for multi-series is ${id}-${seriesIndex}
    const id = changeId.split('-')[0];
    const serumIndex = changeId.split('-').length > 1 ? parseInt(changeId.split('-')[1]) : 0;
    const xAxis =
        updateObj.vis.display.dependentAxis === 'x' ? updateObj.vis.dependent[0] : updateObj.vis.independent[0];
    const yAxis =
        updateObj.vis.display.dependentAxis === 'x' ? updateObj.vis.independent[0] : updateObj.vis.dependent[0];
    const colors = updateObj.vis.colors || { series: {}, groups: {} };

    console.log("CHART VIS", updateObj)


    switch (id) {
        case 'resetDefaultSettings':
            updateObj.vis.display = { dependentAxis: updateObj.vis.display.dependentAxis };
            if (updateObj.vis.independent[0]) {
                updateObj.vis.independent[0].display = {};
                if (updateObj.vis.independent[0].series.length)
                    updateObj.vis.independent[0].series.forEach((serum) => {
                        // eslint-disable-next-line no-param-reassign
                        serum.display = {};
                    });
            }
            if (updateObj.vis.dependent[0]) {
                updateObj.vis.dependent[0].display = {};
                if (updateObj.vis.dependent[0].series[0])
                    updateObj.vis.dependent[0].series.forEach((serum) => {
                        // eslint-disable-next-line no-param-reassign
                        serum.display = {};
                    });
            }
            shouldGetData = false;
            break;

        // Legend settings
        case 'hasLegend':
            updateObj.vis.display.hasLegend = value;
            shouldGetData = false;
            break;
        case 'legendPosition':
            updateObj.vis.display.legendPosition = value;
            shouldGetData = false;
            break;
        case 'legendItemGap':
            updateObj.vis.display.legendItemGap = value;
            shouldGetData = false;
            break;
        // case 'legendBackgroundColor':
        //     updateObj.vis.display.legendBackgroundColor = value;
        //     shouldGetData = false;
        //     break;
        // case 'legendTextColor':
        //     updateObj.vis.display.legendTextColor = value;
        //     shouldGetData = false;
        //     break;
        
        case 'addFilters':
            updateObj.vis.dependent[0].series.find((serum, index) => {
                if (serum.id === value.id) {
                    updateObj.vis.dependent[0].series[index].filters = value.filters;
                    return true;
                }
                return false;
            });
            break;
        case 'clearUnitColors':
            colors.series = {};
            colors.groups = {};
            updateObj.vis.colors = colors;
            shouldGetData = false;
            break;
        case 'addColor':
            if (!colors.series) colors.series = {};
            colors.series[value.serumId] = value.color;
            updateObj.vis.colors = colors;
            shouldGetData = false;
            break;
        case 'deleteColor':
            delete colors.series[value.id];
            updateObj.vis.colors = colors;
            shouldGetData = false;
            break;
        case 'addGroupByColor':
            if (!colors.groups) colors.groups = {};
            colors.groups[value.name] = value.color;
            updateObj.vis.colors = colors;
            shouldGetData = false;
            break;
        case 'gradients':
            if (!colors.gradients) colors.gradients = {};
            colors.gradients[value.name] = value.color;
            updateObj.vis.colors = colors;
            shouldGetData = false;
            break;
        case 'deleteGroupByColor':
            delete colors.groups[value.name];
            updateObj.vis.colors = colors;
            shouldGetData = false;
            break;
        case 'stackGroups':
            updateObj.vis.display.stackGroups = value;
            shouldGetData = false;
            break;
        case 'stackOffset':
            updateObj.vis.display.stackOffset = value;
            shouldGetData = false;
            break;
        case 'barGap':
            updateObj.vis.display.barGap = Number(value);
            shouldGetData = false;
            break;
        case 'barCategoryGap':
            updateObj.vis.display.barCategoryGap = parseInt(value);
            shouldGetData = false;
            break;
        case 'radarOuterRadius':
            updateObj.vis.dependent[0].display.radarOuterRadius = parseInt(value);
            shouldGetData = false;
            break;
        case 'radarStartAngle':
            updateObj.vis.dependent[0].display.radarStartAngle = parseInt(value);
            shouldGetData = false;
            break;
        case 'innerRadius':
            updateObj.vis.dependent[serumIndex].display.innerRadius = parseInt(value);
            shouldGetData = false;
            break;
        case 'outerRadius':
            updateObj.vis.dependent[serumIndex].display.outerRadius = parseInt(value);
            shouldGetData = false;
            break;
        case 'startAngle':
            updateObj.vis.dependent[serumIndex].display.startAngle = parseInt(value);
            shouldGetData = false;
            break;
        case 'hasDot':
            updateObj.vis.dependent[0].series[serumIndex].display.hasDot = value;
            shouldGetData = false;
            break;
        case 'dotSize':
            updateObj.vis.dependent[0].series[serumIndex].display.dotSize = value;
            shouldGetData = false;
            break;
        case 'lineType':
            updateObj.vis.dependent[0].series[serumIndex].display.lineType = value;
            shouldGetData = false;
            break;
        case 'fillOpacity':
            updateObj.vis.dependent[0].series[serumIndex].display.fillOpacity = parseFloat(value);
            shouldGetData = false;
            break;
        case 'xAxisZoomVisible':
            xAxis.display.zoomVisible = value;
            shouldGetData = false;
            break;
        case 'yAxisZoomVisible':
            yAxis.display.zoomVisible = value;
            shouldGetData = false;
            break;
        case 'xAxisTitle':
            xAxis.title = value;
            shouldGetData = false;
            break;
        case 'yAxisTitle':
            yAxis.title = value;
            shouldGetData = false;
            break;
        case 'xAxisHasTitle':
            xAxis.display.hasTitle = value;
            shouldGetData = false;
            break;
        case 'yAxisHasTitle':
            yAxis.display.hasTitle = value;
            shouldGetData = false;
            break;
        case 'xAxisHasLabels':
            xAxis.display.hasLabels = value;
            shouldGetData = false;
            break;
        case 'xAxisSlantLabels':
            xAxis.display.slant = value;
            shouldGetData = false;
            break;
        case 'yAxisHasLabels':
            yAxis.display.hasLabels = value;
            shouldGetData = false;
            break;
        case 'xAxisMin':
            xAxis.display.typeMin = value;
            shouldGetData = false;
            break;
        case 'xAxisFixedMin':
            xAxis.display.valueMin = value ?? null;
            shouldGetData = false;
            break;
        case 'xAxisMax':
            xAxis.display.typeMax = value;
            shouldGetData = false;
            break;
        case 'xAxisFixedMax':
            xAxis.display.valueMax = value ?? null;
            shouldGetData = false;
            break;
        case 'yAxisMin':
            yAxis.display.typeMin = value;
            shouldGetData = false;
            break;
        case 'yAxisFixedMin':
            yAxis.display.valueMin = value ?? null;
            shouldGetData = false;
            break;
        case 'yAxisMax':
            yAxis.display.typeMax = value;
            shouldGetData = false;
            break;
        case 'yAxisFixedMax':
            yAxis.display.valueMax = value ?? null;
            shouldGetData = false;
            break;
        case 'xAxisOrientation':
            xAxis.display.orientation = value;
            shouldGetData = false;
            break;
        case 'yAxisOrientation':
            yAxis.display.orientation = value;
            shouldGetData = false;
            break;
        case 'xAxisScale':
            xAxis.display.scale = value;
            shouldGetData = false;
            break;
        case 'yAxisScale':
            yAxis.display.scale = value;
            shouldGetData = false;
            break;
        case 'xReversed':
            xAxis.display.reversed = value;
            shouldGetData = false;
            break;
        case 'yReversed':
            yAxis.display.reversed = value;
            shouldGetData = false;
            break;
        case 'gridHorizontal':
            if (!updateObj.vis.display.grid) updateObj.vis.display.grid = {};
            updateObj.vis.display.grid.horizontal = value;
            shouldGetData = false;
            break;
        case 'gridVertical':
            if (!updateObj.vis.display.grid) updateObj.vis.display.grid = {};
            updateObj.vis.display.grid.vertical = value;
            shouldGetData = false;
            break;
        case 'pieHasLabels':
            updateObj.vis.dependent[serumIndex].display.pieHasLabels = value;
            shouldGetData = false;
            break;
        case 'hasTooltip':
            updateObj.vis.display.hasTooltip = value;
            shouldGetData = false;
            break;

        case 'showVisualMap':
            updateObj.vis.display.showVisualMap = value;
            shouldGetData = false;
            break;
        case 'hasAnimation':
            updateObj.vis.display.hasAnimation = value;
            shouldGetData = false;
            break;
        case 'xOperation':
            xAxis.series[serumIndex].operation = value === 'none' ? '' : value;
            if (
                updateObj.vis.orderBy[0] &&
                updateObj.vis.orderBy[0].feature._id === xAxis.series[serumIndex].feature._id
            ) {
                updateObj.vis.orderBy[0].operation = value === 'none' ? '' : value;
            }
            checkAndSwitchAxes(updateObj.vis, features);
            break;
        case 'yOperation':
            yAxis.series[serumIndex].operation = value === 'none' ? '' : value;
            if (
                updateObj.vis.orderBy[0] &&
                updateObj.vis.orderBy[0].feature._id === yAxis.series[serumIndex].feature._id
            ) {
                updateObj.vis.orderBy[0].operation = value === 'none' ? '' : value;
            }
            checkAndSwitchAxes(updateObj.vis, features);
            break;
        case 'zOperation':
            updateObj.vis.otherDimensions[0].series[0].operation = value === 'none' ? '' : value;
            break;
        case 'gradientOperation':
            updateObj.vis.gradientDimension[0].series[0].operation = value === 'none' ? '' : value;
            break;
        case 'tooltipOperation':
            updateObj.vis.tooltip.series[serumIndex].operation = value === 'none' ? '' : value;
            break;
        case 'orderByOperation':
            updateObj.vis.orderBy[0].operation = value === 'none' ? '' : value;
            break;
        case 'xOperationNumberOfBins':
            xAxis.series[serumIndex].numberOfBins = value;
            break;
        case 'yOperationNumberOfBins':
            yAxis.series[serumIndex].numberOfBins = value;
            break;
        case 'zOperationNumberOfBins':
            updateObj.vis.otherDimensions[0].series[0].numberOfBins = value;
            break;
        case 'gradientOperationNumberOfBins':
            updateObj.vis.gradientDimension[0].series[0].numberOfBins = value;
            break;
        case 'tooltipOperationNumberOfBins':
            updateObj.vis.tooltip.series[serumIndex].numberOfBins = value;
            break;
        case 'orderByOperationNumberOfBins':
            updateObj.vis.orderBy[0].numberOfBins = value;
            break;
        case 'orderType':
            updateObj.vis.orderBy[0].type = value || 'ASC';
            break;
        case 'yNugget':
        case 'xNugget':
        case 'zNugget':
        case 'gradientNugget':
        case 'tooltipNugget':
            shouldClearData = updateSeriesNugget(updateObj.vis, id, value, features, shouldClearData);
            // Only keep colors for the series in the dependent and independent axes (prevents a buildup of unused keys in the colors.series object)
            Object.keys(updateObj.vis?.colors?.series || {}).forEach((key) => {
                if (
                    ![...(updateObj.vis.dependent[0]?.series || []), ...(updateObj.vis.independent[0]?.series || [])]
                        .map((val) => val.id)
                        .includes(key)
                )
                    delete updateObj.vis.colors.series[key];
            });
            break;
        case 'orderByNugget':
            updateObj.vis.orderBy = value;
            if (value[0]) {
                const axisSerum =
                    value[0]?.feature?._id &&
                    (updateObj.vis.dependent[0]?.series.find((serum) => serum.feature._id === value[0].feature._id) ||
                        updateObj.vis.independent[0]?.series.find(
                            (serum) => serum.feature._id === value[0].feature._id,
                        ));
                if (axisSerum) {
                    updateObj.vis.orderBy[0].operation = axisSerum.operation;
                }
            }
            break;
        case 'groupOperation':
            if (updateObj.vis.groupBy && updateObj.vis.groupBy[0]) {
                updateObj.vis.groupBy[0].operation = value === 'none' ? '' : value;
            }
            break;
        case 'groupOperationNumberOfBins':
            updateObj.vis.groupBy[0].numberOfBins = value;
            break;
        case 'groupByNugget':
            updateObj.vis.groupBy = value;
            if (updateObj.vis.colors?.groups && value.length === 0) {
                // clears groupBy overrides when groupBy nugget is cleared
                updateObj.vis.colors.groups = {};
            }
            break;
        case 'filters':
            updateObj.vis.filters = value;
            break;
        case 'mainChartType': {
            if (value === '') return false;
            const xyCharts = Object.keys(xyChartTypes);
            const switchingBetweenXy = xyCharts.includes(updateObj.vis.chartType) && xyCharts.includes(value);
            if (!switchingBetweenXy) {
                updateObj.vis = { ...defaultChartModel.vis, filters: updateObj.vis.filters };
            }
            updateObj.vis.chartType = value;
            break;
        }
        case 'chartType':
            if (value === '') return false;
            updateObj.vis.dependent[0].series[serumIndex].chartType = value;
            updateObj.vis.dependent[0].series[serumIndex].display = {};
            break;

        default:
            // eslint-disable-next-line no-console
            console.error('I didnt think there was another case', changeId, value);
            return false;
    }
    return { updateObj, shouldGetData, shouldClearData };
}
