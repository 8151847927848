import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import the useTranslation hook

/* eslint-disable */
import logoWW from '../../../../assets/logo.svg';
import logoY_en from '../../../../assets/y_logo_en.png';
import logoY_fr from '../../../../assets/y_logo_fr.png';

import { Button, Card, Form, Input, TextButton } from '../../../shared';
import LanguageToggle from '../../_components/LanguageToggle'; // Adjust the path as needed

import LoginModel from '../../_models/login'; // TODO: refactor this.
import msalInstance from './msalInstance';
import { t } from '../../../../utils/translate'

require('./styles.scss');

export default function Login() {
    const { t } = useTranslation();  // Destructure the t function from useTranslation
    const { errors, loading } = useSelector((state) => state.login);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isInteractionInProgress, setIsInteractionInProgress] = React.useState(false);
    const location = useLocation();
    const matchedEmail = location.search.match(/email=([^&]*)/);  // Rename to avoid shadowing
    const orgLogin = new URLSearchParams(location.search).get('organization');
  
    const selectedLanguage = localStorage.getItem('language') || 'en'; // Default to 'en' if not set

    const logo = orgLogin === 'ymca'
      ? (selectedLanguage === 'fr' ? logoY_fr : logoY_en)
      : logoWW;

    const loginMicrosoft = async (email = '') => {  // Define loginMicrosoft before using it
        if (isInteractionInProgress) {
            console.log('Interaction is already in progress.');
            return;
        }

        setIsInteractionInProgress(true);

        try {
            const authCode = await msalInstance.loginPopup({ scopes: ['user.read'], loginHint: email });
            const action = await dispatch(LoginModel.loginMicrosoft(authCode));

            if (action?.type === 'LOGIN_SUCCESS') {
                navigate('/projects');
            }
        } catch (error) {
            console.error("Error during login or dispatch:", error);
        } finally {
            setIsInteractionInProgress(false);
        }
    };

    const onLogin = (form) =>
        dispatch(LoginModel.login(form)).then((action, error) => {
            if (action?.type === 'LOGIN_SUCCESS') navigate('/projects');
            if (action?.type === 'LOGIN_SUCCESS_REDIRECT') navigate(`/login/reset/${action.resetToken}`);
            if (action?.type === 'LOGIN_MICROSOFT_REDIRECT') {
                loginMicrosoft(action.email);
            }
        });

    const handleForgotPassword = () => navigate('/login/recover');

    return (
        <div>
            <Card className="Login__Card">
                    <img
                        src={logo}
                        alt="Organization Logo"
                        style={{ width: '160px', height: '160px', objectFit: 'contain' }}
                    />

                <Form className="Login__Login" onSubmit={onLogin}>
                    <main>
                        <Input
                            id="email"
                            type="text"
                            label={t('email', 'Email')}  // Use t function for translation
                            errors={errors.email}
                            value={(matchedEmail && matchedEmail[1]) || ''}  // Use renamed variable
                            gray
                            required
                        />
                        <Input
                            id="password"
                            type="password"
                            label={t('password')}  // Use t function for translation
                            errors={errors.password}
                            gray
                            required
                        />
                    </main>
                    <div className="Login__Login_forgot">
                        <TextButton gray onClick={handleForgotPassword}>
                            {t('forgot_password')} 
                        </TextButton>
                    </div>
                    <div className="Login__buttons">
                        <Button id="submit" className="Login__button" blue loading={loading}>
                            {t('log_in')} 
                        </Button>
                    </div>
                </Form>

                <div className="centered-text">
                    {t('or')} 
                </div>

                <div className="Login__microsoft">
                    <Button
                        id="submit_microsoft"
                        className="Login__button"
                        blue
                        onClick={() => loginMicrosoft((matchedEmail && matchedEmail[1]) || '')}  // Use renamed variable
                        disabled={isInteractionInProgress}
                    >
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAABnRSTlMAAAAAAABupgeRAAAAWklEQVR4AWP4FKBEJPrip8wABrV7GOp3EYtGhgWjFoxaMGrBqAXf/OSIRD995CEWVO9hqN1FLBoOYNlnotF3iI7/O4lHQOVL3hONPo9agB2NWjBqwagFoxYAAB2zMZ7bQW2tAAAAAElFTkSuQmCC" alt="Icon" className="button-icon" />
                        {t('sign_in_microsoft')} 
                    </Button>
                </div>
            </Card>

            <LanguageToggle />

        </div>
    );
}

Login.propTypes = {};
Login.defaultProps = {};




// FUTURE: SIGN IN WITH GOOGLE AND APPLE
// <div className="Login__google">
//     <Button id="submit_google" className="Login__button" blue loading={loading} >
//         <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAilBMVEVHcEz9/f3////m6Ojw8fH9/f3////////09fX5+fnz8/Tt7u/t7u////9Bf+1LnFTbUDs7lkX7+vnYPB72uzL219MzeO3aSjP2thngaUcmc+3l7+66zffH3sqaxZ/nlYv97M2AtobkhnrrqKBAnFn40IBto1ONp0u5rDjkioSlw9qOrvNAknlznfE4nf38AAAADXRSTlMAs/FBKzJvlxqogENCbeDs1AAAAUFJREFUOI2VU9t2gyAQVGNU0lqQi0ismqTpvf3/3yt3kNhz2nlxYWaX2UWy7D8oqxqAuiq32aZuPermlo9oLUnTizZBsSqyT2mFfZRvt9giOBcLs8tQw9RngnCEEOdEGEmx9vdEkAMnLHbaBJ4TCY7IsjpEF2CKJxeGMbt43pbQoVD5sjBW4tCJb/E0CEQiIm61UsEzpeJ9YxaVs0ApHU566/hgcHx0JnIVDFKAtaC3gl4L8r8I0iN6CS+ovcmBvs3B27f0MDqTts3Xazc6fpROe9+mGdTLFUJoFVid8OkHlQEVTZ0UdPOI8Th/qS61ZRBd1qwUsJOQHzsF90eASAGN7KMNBSTMBCZoJR2cjJfwHqz76SyT4Xmyy+h93G/cU3sX/9YlTmmcvi+w5kF2gwb4KhhsPD2F3QHkOTjsttlf8ANQZSoZjCvBoAAAAABJRU5ErkJggg==" alt="Icon" className="button-icon" />
//         Sign in with Google
//     </Button>
// </div>

// <div className="Login__apple">
//     <Button id="submit_apple" className="Login__button" blue loading={loading} >
//         <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC5UlEQVR4Aa1XQ3hkQRjc+ynX2OZtbfu+tm3b1nlt27a9O4qNS5xxbdd+cTKvXydT31fJoPuvmvf6/ejw86dBlX6CwwQXCq6t5cLaz/xV4+ld6F8r9NdgsCAjIwf5+UUoLCwBydf8jN+JNQbBddzjDQM+gocErRSyWm2QgWu4lntq9/q01UAfwYKCgmK43W6ognu4lzEE+6oamCboLC0tR3vBGIwlOF2vgZm5uQWoqamBXrhcLpw5cxZ79uxFKxCxrGBMxpYZ6Eu33KAXNDp+/AQEBgbzv8Y6Kxi7+e1ofuAKVS/7zp27KE7i6dNnem5HAbVaM3CYh0YF/PWRkdEUpxHoQe3BPNTcQJCgTc9pT0tLh8VigdPpBLFv3368evVKBC7A16/fkJmZKX06qCXo39jAej67Wnjx4iVGjBiJ0NBwBAeHYsCAgTh48BCuXLmCKVOmIioqBrwS4eGRGDduPMxmMzyBWtRsbMCglWSePXuOkJAwCuhmnz79YLVaPSUrGjDWGQhgCvWEyspKdOrURUk8JiYO799/0Exg1KQ2DQxjHveEO3fuKomTPBcyUJPaNLCQxcQTNm3arGzAYDBABmoK7UU0sE7rAC5dukxJPCgoRPy6DMhATWpLDWzbtl35Cty//0DBgOQW3LhxU9nAsGEj4HA4dN0CySHkwvy6bKfECRMmISsrS34IZY8hMXnyFAZV5rFjx6WPoa5E9PnzZ2XxpKQUlJaWaiUik1IqXrBgkZKB06fPwBOKiv4fwA3Ni5FdK3NVVFSgd+++usRnzJilXIzII7JynJOTAxaa7t17Yt68+bh37z6+fPmKCxcuYvToMejVqzdWrVrNMi0rx4cVGxIFKDQkCi2ZAhRaMklTavWqeF6epCltxuneasvLyurb8lmqg0lfLw4m/dozmh0RtBUV6R/NuJZ7avf6eGs4ZeIwMoVmZrYcTvkZv+MarlUZTlUZIDi8diRfX8uFtZ8FqMb7Bx+2VJbBTrlcAAAAAElFTkSuQmCC" alt="Icon" className="button-icon" />
//         Sign in with Apple
//     </Button>
// </div>
