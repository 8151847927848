export const _getStackedSettings = (vis) => {
    const chartTypes = new Set([vis.chartType]);
    vis.dependent[0].series.forEach((serum) => chartTypes.add(serum.chartType));

    const onlyOneSeries = !vis.groupBy.length && vis.dependent[0].series.length < 2;
    // only bar graphs and area graphs have stacking options
    if (onlyOneSeries || !(chartTypes.has('bar') || chartTypes.has('area'))) return [];

    return [
        {
            id: 'stackGroups',
            type: 'select',
            label: 'Stack Data Series',
            value: vis.display.stackGroups || 'none',
            options: [
                { label: 'Not stacked', value: 'none' },
                { label: 'Stacked', value: 'all' },
            ],
        },
    ];
};

export const _generalSettings = (vis) => ({
    name: 'Chart',
    inputs: [
        { id: 'hasTooltip', type: 'switch', label: 'Show Tooltips', value: vis.display?.hasTooltip || true },
        { id: 'hasLegend', type: 'switch', label: 'Show Legend', value: vis.display?.hasLegend || false },
        {
            id: 'legendPosition',
            type: 'select',
            label: 'Legend Position',
            value: vis.display?.legendPosition || 'top', // Default position: top
            options: [
                { label: 'Top', value: 'top' },
                { label: 'Bottom', value: 'bottom' },
                { label: 'Left', value: 'left' },
                { label: 'Right', value: 'right' },
            ],
        },
        {
            id: 'legendItemGap',
            type: 'number',
            label: 'Legend ItemGap',
            value: vis.display?.legendItemGap || 10,
            max: 50,
            step: 1,
        },
        // {
        //     id: 'legendBackgroundColor',
        //     type: 'color',
        //     label: 'Legend Background Color',
        //     value: vis.display?.legendBackgroundColor || '#ffffff', // Default to white
        // },
        // {
        //     id: 'legendTextColor',
        //     type: 'color',
        //     label: 'Legend Text Color',
        //     value: vis.display?.legendTextColor || '#000000', // Default to black
        // },
        { id: 'hasAnimation', type: 'switch', label: 'Enable Animations', value: vis.display?.hasAnimation || true },
        ..._getStackedSettings(vis),
    ],
});


export const _axisScaleItems = {
    number: [
        { label: 'Linear', value: 'linear' },
        { label: 'Logarithmic', value: 'log' },
    ],
};

export const _getXYSettings = (vis, props) => {
    const { dependentAxis } = vis.display;
    const xAxes = dependentAxis === 'x' ? vis.dependent : vis.independent;
    const yAxes = dependentAxis === 'x' ? vis.independent : vis.dependent;

    return [
        _generalSettings(vis),
        ...xAxes.map((x, i) => ({
            name: xAxes.length > 1 ? `X Axis ${i}` : 'X Axis',
            inputs: [
                {
                    id: 'xAxisTitle',
                    type: 'text',
                    label: 'X Axis Title',
                    value: x.title,
                    placeholder: props.xAxisTitle,
                },
                {
                    id: 'xAxisZoomVisible',
                    type: 'switch',
                    label: 'Enable Zoom',
                },
                { id: 'xAxisHasTitle', type: 'switch', label: 'Show Axis Title' },
                { id: 'xAxisHasLabels', type: 'switch', label: 'Show Axis Labels' },
                { id: 'xAxisSlantLabels', type: 'switch', label: 'Slant Axis Labels' },
                {
                    id: 'xAxisOrientation',
                    type: 'select',
                    label: 'Axis Position',
                    options: [
                        { label: 'Top', value: 'top' },
                        { label: 'Bottom', value: 'bottom' },
                    ],
                },
                { id: 'gridVertical', type: 'switch', label: 'Show Gridlines' },
                {
                    id: 'xAxisScale',
                    type: 'select',
                    label: 'Axis Scale',
                    options: _axisScaleItems[props._xType],
                    hidden: !_axisScaleItems[props._xType],
                },
                {
                    id: 'xAxisMin',
                    type: 'select',
                    label: 'Axis Min',
                    value: x.display?.typeMin || 'auto',
                    options: [
                        { label: 'Auto', value: 'auto' },
                        { label: 'Data Min', value: 'data' },
                        { label: 'Fixed Value', value: 'fixed' },
                    ],
                    hidden: props._xType !== 'number',
                },
                {
                    id: 'xAxisFixedMin',
                    label: 'Min X Value',
                    type: 'number',
                    hidden: x.display?.typeMin !== 'fixed',
                    autosize: true,
                },
                {
                    id: 'xAxisMax',
                    type: 'select',
                    label: 'Axis Max',
                    value: x.display?.typeMax || 'auto',
                    options: [
                        { label: 'Auto', value: 'auto' },
                        { label: 'Data Max', value: 'data' },
                        { label: 'Fixed Value', value: 'fixed' },
                    ],
                    hidden: props._xType !== 'number',
                },
                {
                    id: 'xAxisFixedMax',
                    label: 'Max X Value',
                    type: 'number',
                    hidden: x.display?.typeMax !== 'fixed',
                    autosize: true,
                },
                { id: 'xReversed', type: 'switch', label: 'Reverse Axis' },
            ],
        })),
        ...yAxes.map((y, i) => ({
            name: yAxes.length > 1 ? `Y Axis ${i}` : 'Y Axis',
            inputs: [
                {
                    id: 'yAxisTitle',
                    type: 'text',
                    label: 'Y Axis Title',
                    value: y.title,
                    placeholder: props.yAxisTitle,
                },
                {
                    id: 'yAxisZoomVisible',
                    type: 'switch',
                    label: 'Enable Zoom',
                },
                { id: 'yAxisHasTitle', type: 'switch', label: 'Show Axis Title' },
                { id: 'yAxisHasLabels', type: 'switch', label: 'Show Axis Labels' },
                {
                    id: 'yAxisOrientation',
                    type: 'select',
                    label: 'Axis Position',
                    options: [
                        { label: 'Left', value: 'left' },
                        { label: 'Right', value: 'right' },
                    ],
                },
                { id: 'gridHorizontal', type: 'switch', label: 'Show Gridlines' },
                {
                    id: 'yAxisScale',
                    type: 'select',
                    label: 'Axis Scale',
                    options: _axisScaleItems[props._yType],
                    hidden: !_axisScaleItems[props._yType],
                },
                {
                    id: 'yAxisMin',
                    type: 'select',
                    label: 'Axis Min',
                    value: y.display?.typeMin || 'auto',
                    options: [
                        { label: 'Auto', value: 'auto' },
                        { label: 'Data Min', value: 'data' },
                        { label: 'Fixed Value', value: 'fixed' },
                    ],
                    hidden: props._yType !== 'number',
                },
                {
                    id: 'yAxisFixedMin',
                    label: 'Min Y Value',
                    type: 'number',
                    hidden: y.display?.typeMin !== 'fixed',
                    autosize: true,
                },
                {
                    id: 'yAxisMax',
                    type: 'select',
                    label: 'Axis Max',
                    value: y.display?.typeMax || 'auto',
                    options: [
                        { label: 'Auto', value: 'auto' },
                        { label: 'Data Max', value: 'data' },
                        { label: 'Fixed Value', value: 'fixed' },
                    ],
                    hidden: props._yType !== 'number',
                },
                {
                    id: 'yAxisFixedMax',
                    label: 'Max Y Value',
                    type: 'number',
                    hidden: y.display?.typeMax !== 'fixed',
                    autosize: true,
                },
                { id: 'yReversed', type: 'switch', label: 'Reverse Axis' },
            ],
        })),
    ];
};

export const _getSeriesSettings = (vis, chartProps) => {
    const settings = [];
    const { series } = vis.dependent[0];
    const { serumNameFormatter } = chartProps;

    series.forEach((serum, i) => {
        const serumChartType = serum.chartType || vis.chartType;
        const seriesSettings = {
            name:
                serumNameFormatter &&
                `${serumChartType[0].toUpperCase() + serumChartType.substring(1)} - ${serumNameFormatter(serum)}`,
            inputs: [],
        };
        if (['area', 'line'].includes(serumChartType)) {
            // line and area have dots and lineTypes
            seriesSettings.inputs.push({
                id: `lineType-${i}`,
                type: 'select',
                label: 'Line Type',
                value: chartProps.dependentSeriesProps[serum.id].lineType,
                options: [
                    { id: 'linear', label: 'linear', value: 'linear' },
                    { id: 'smooth', label: 'smooth', value: 'smooth' },
                    { id: 'step', label: 'step', value: 'step' },
                ],
            });
            seriesSettings.inputs.push({
                id: `hasDot-${i}`,
                type: 'switch',
                label: 'Show Dots',
                value: chartProps.dependentSeriesProps[serum.id].hasDot,
            });
        }
        if (['area', 'line', 'scatter'].includes(serumChartType)) {
            seriesSettings.inputs.push({
                id: `dotSize-${i}`,
                type: 'number',
                label: 'Dot Size',
                value: chartProps.dependentSeriesProps[serum.id].dotSize,
                min: 4,
                max: 50,
                step: 2,
                autosize: true,
            });
        }
        if (['area', 'radar', 'bar', 'scatter'].includes(serumChartType)) {
            seriesSettings.inputs.push({
                id: `fillOpacity-${i}`,
                type: 'number',
                label: 'Fill Opacity',
                value: chartProps.dependentSeriesProps[serum.id].fillOpacity,
                min: 0,
                max: 1,
                step: 0.1,
                autosize: true,
            });
        }
        if (['scatter'].includes(serumChartType)) {
            seriesSettings.inputs.push({ id: 'showVisualMap', type: 'switch', label: 'Show Heat Map' });
        }
        if (serumChartType === 'bar') {
            seriesSettings.inputs.push(
                {
                    id: 'barCategoryGap',
                    label: 'Space Between Axis Values',
                    type: 'number',
                    min: 0,
                    max: 50,
                    step: 2,
                    autosize: true,
                },
                {
                    id: 'barGap',
                    label: 'Space Between Bars',
                    type: 'number',
                    min: 0,
                    max: 50,
                    step: 0.1,
                    autosize: true,
                },
            );
        }
        if (seriesSettings.inputs.length) settings.push(seriesSettings);
    });
    return settings;
};

export const _getPieSettings = (vis, chartProps) => [
    _generalSettings(vis),
    {
        name: 'Pie',
        inputs: [
            {
                id: 'pieHasLabels',
                type: 'switch',
                label: 'Show Labels',
                value: chartProps.dependentAxisProps[0].pieHasLabels,
            },
            {
                id: 'outerRadius',
                type: 'number',
                label: 'Outer Radius',
                step: 5,
                value: chartProps.dependentAxisProps[0].outerRadius,
                autosize: true,
            },
            {
                id: 'innerRadius',
                type: 'number',
                label: 'Inner Radius',
                step: 5,
                value: chartProps.dependentAxisProps[0].innerRadius,
                autosize: true,
            },
            {
                id: 'startAngle',
                type: 'number',
                label: 'Start Angle',
                step: 5,
                value: chartProps.dependentAxisProps[0].startAngle,
                autosize: true,
            },
        ],
    },
];

export const _getRadarSettings = (vis) => [
    _generalSettings(vis),
    {
        name: 'Radar',
        inputs: [
            { id: 'radarOuterRadius', type: 'number', label: 'Outer Radius', step: 5, autosize: true },
            { id: 'radarStartAngle', type: 'number', label: 'Start Angle', step: 5, autosize: true },
        ],
    },
];
